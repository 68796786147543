.btn-upload {
	margin-bottom: 12px;
}
.jsThumbnailImageWrapper {
	padding-top: 8px;
	overflow-y: auto;
}
.jsThumbnailImageWrapper figure {
	position: relative;
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 20px;
	background-color: #fff;
	border: 1px solid #eee;
	padding: 3px;
	border-radius: 3px;
	vertical-align: bottom;
}
.jsThumbnailImageWrapper i.removeIcon {
	position: absolute;
	top:-10px;
	right:-10px;
	color: #f56954;
	font-size: 2em;
	background: white;
	border-radius: 20px;
	height: 25px;
}

figure.ui-state-highlight {
	border: none;
	width:100px;
	height: 0;
}
