@import "Asgard/vars";
@import "Asgard/notifications";
@import "Asgard/mediaModule";
@import "mixins";
@import "footer";

@import "skins/blue.less";

// For the appended elements
.sidebar-menu .append {
  margin-top: -44px;
}
.sidebar-menu .treeview-menu .append {
  margin-top: -30px;
}
.skin-blue .sidebar-menu .append:hover,
.skin-blue .sidebar-menu > li.active > a.append {
  color: #fff;
  background: none;
  border: 0;
}

.sidebar-mini.sidebar-collapse .sidebar-menu a.append,
.sidebar-mini.sidebar-collapse .sidebar-menu li.menu-title {
  display: none;
}

// Correctly aligning the checkbox labels
.checkbox label {
  padding-left: 0;
}
.checkbox label div {
  margin-right: 5px;
  margin-top: -2px;
}

.slider-slides{
    .edit-icon{
        position: absolute;
        top: -10px;
        right: 25px;
        font-size: 2em;
        background: white;
        height: 25px;
        display: flex;
        cursor: pointer;
    }
}
.modal{
    input{
        margin: 5px 0;
    }
}