@import "blue/vars";

.skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
  background: #202227;
}


.skin-blue .sidebar-menu > li.menu-title {
  color: #93AFBB;
  background: lighten(@sidebar-light-bg, 2%);
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}
